import _ from 'lodash';
import dynamic from 'next/dynamic';

const CardHNoneImage = dynamic(import('src/components/cards/card-h-none-image'));

const SectionHPopular = ({ data, heading, count = 4, large = false, description = false, path = '#', eventDataLayer }) => {
  if (_.isEmpty(data)) return null;
  const baseValue = data[0]?.pageviews;

  const progressBackground = (value, base) => {
    const backgroundBar = (value * 100) / base;

    return `linear-gradient(to right, rgba(134,182,255,.18) ${backgroundBar}%, #ffffff ${backgroundBar}%)`;
  };

  return (
    <div className='block-wrapper'>
      <div className='block-title'>
        <h2>{heading}</h2>
      </div>
      <div className={`row block-content ${!large ? '--small' : ''}`}>
        {data.slice(0, count).map((item, index) => (
          <div
            key={index}
            className='col-12 block-item percent-animate pb-2'
            style={{
              backgroundImage: progressBackground(item.pageviews, baseValue)
            }}
          >
            <CardHNoneImage
              data={item}
              description={description}
              large={large}
              heading={heading}
              //! DATALAYER
              eventDataLayer={{
                ...eventDataLayer,
                data: {
                  ...eventDataLayer?.data,
                  title: item?.title,
                  index: index + 1
                }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHPopular;
